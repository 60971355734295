import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


// 意见保存内容
export function saveFeedback(params) {
	return loginRequest({
    url: '/frontend/website/opinion/add',
		method: 'post',
		data:Qs.stringify(params)
	})
}



